<script setup lang="ts">
import { Category } from '@shopware-pwa/types';
import { getSrcSetForMedia } from '@shopware-pwa/helpers-next';
import { handleClick } from '@/helpers/clickHandler';

const emits = defineEmits<{
  (e: 'closeMenu'): void;
}>();

const isSideMenuOpened = inject('isSideMenuOpened', ref(false));
const expandedIds = ref<Array<string>>([]);

function isCollapsed(navigationelement: Category): boolean {
  return !expandedIds.value.includes(navigationelement.id);
}

const toggleCollapse = (navigationElement: Category) => {
  if (!isCollapsed(navigationElement)) {
    expandedIds.value = expandedIds.value.filter(
      (el) => el !== navigationElement.id
    );
  } else {
    expandedIds.value.push(navigationElement.id);
  }
};

const { mainNavigation } = useNavigation();

watch(mainNavigation, () => {
  categoriesRef.value = setMainNavigationMapping();
});

type ISubcategories = {
  id: string;
  title: string;
  to: string;
  quantity: any;
  externalLink: any;
  linkNewTab: any;
  highlight: any;
}[];

const bannerMobile = () => {
  const startBannerData: any = [];
  mainNavigation.value?.forEach((element) => {
    if (startBannerData.length === 0) {
      const media: any = element.parent?.customFields
        ?.custom_navigation_image_1_media_media as unknown;
      const media2: any = element.parent?.customFields
        ?.custom_navigation_image_2_media_media as unknown;

      let startBanner1 = {};

      if (media) {
        startBanner1 = {
          headline:
            element.parent?.customFields?.custom_navigation_image_headline_1 ||
            '',
          subheadline:
            element.parent?.customFields
              ?.custom_navigation_image_subheadline_1 || '',
          imageLink:
            element.parent?.customFields?.custom_navigation_link_image_1 || '',
          whiteFont:
            element.parent?.customFields?.custom_navigation_image_white_font_1,
          imageSrc: media?.url,
          srcset: media ? getSrcSetForMedia(media as any) : '',
          altText: media?.alt || '',
          titleText: media?.title || '',
        };

        startBannerData.push(startBanner1);
      }

      let startBanner2 = {};
      if (media2) {
        startBanner2 = {
          headline:
            element.parent?.customFields?.custom_navigation_image_headline_2 ||
            '',
          subheadline:
            element.parent?.customFields
              ?.custom_navigation_image_subheadline_2 || '',
          imageLink:
            element.parent?.customFields?.custom_navigation_link_image_2 || '',
          whiteFont:
            element.parent?.customFields?.custom_navigation_image_white_font_2,
          imageSrc: media2.url,
          srcset: media2 ? getSrcSetForMedia(media2 as any) : '',
          altText: media2?.alt || '',
          titleText: media2?.title || '',
        };

        startBannerData.push(startBanner2);
      }
    }
  });

  return startBannerData;
};

const setMainNavigationMapping = () => {
  return (
    mainNavigation.value?.map((category: any) => {
      //TODO: fix types
      const media: any = category.customFields
        ?.custom_navigation_image_1_media_media as unknown;
      const customfields = category.customFields;

      const bannerData: any = [];
      let banner1 = {};
      if (media?.url) {
        banner1 = {
          imageSrc: media?.url || '',
          headline: customfields.custom_navigation_image_headline_1 || '',
          subheadline: customfields.custom_navigation_image_subheadline_1 || '',
          imageLink: customfields.custom_navigation_link_image_1 || '',
          whiteFont: customfields.custom_navigation_image_white_font_1,
          srcset: media ? getSrcSetForMedia(media as any) : '',
          altText: media?.alt || '',
          titleText: media?.title || '',
        };

        bannerData.push(banner1);
      }

      const media2: any = category.customFields
        ?.custom_navigation_image_2_media_media as unknown;
      let banner2 = {};
      if (media2?.url) {
        banner2 = {
          imageSrc: media2?.url || '',
          headline: customfields.custom_navigation_image_headline_2 || '',
          subheadline: customfields.custom_navigation_image_subheadline_2 || '',
          imageLink: customfields.custom_navigation_link_image_2 || '',
          whiteFont: customfields.custom_navigation_image_white_font_2,
          srcset: media2 ? getSrcSetForMedia(media2 as any) : '',
          altText: media2?.alt || '',
          titleText: media2?.title || '',
        };

        bannerData.push(banner2);
      }

      let subcategories: ISubcategories = [];
      category.children?.forEach((subcategory: any) => {
        subcategories.push({
          id: subcategory.id,
          title: subcategory.name || '',
          to: subcategory.externalLink
            ? subcategory.externalLink
            : `/${subcategory.seoUrls?.[0]?.seoPathInfo}` || '',
          externalLink: subcategory.externalLink ? true : false,
          linkNewTab: subcategory.linkNewTab,
          quantity:
            typeof subcategory?.extensions != 'string' &&
            typeof subcategory.extensions?.productsCount !== 'string'
              ? subcategory.extensions?.productsCount?.count || '0'
              : '0',
          highlight:
            subcategory.customFields?.custom_navigation_headline_colored ||
            false,
        });
      });

      return {
        id: category.id,
        subtitle:
          category.customFields &&
          category.customFields?.custom_navigation_subheadline
            ? category.customFields?.custom_navigation_subheadline
            : '',
        title: category.translated.name || '',
        to: category.externalLink
          ? category.externalLink
          : `/${category.seoUrls?.[0]?.seoPathInfo}` || '',
        showAll: category.customFields
          ?.custom_navigation_headline_show_all as any,
        subcategories: subcategories,
        externalLink: category.externalLink ? true : false,
        linkNewTab: category.linkNewTab,
        bannerData: bannerData,
        quantity:
          typeof category?.extensions != 'string' &&
          typeof category.extensions?.productsCount !== 'string'
            ? category.extensions?.productsCount?.count || '0'
            : '0',
        highlight:
          category.customFields?.custom_navigation_headline_colored || false,
      };
    }) || []
  );
};

const categoriesRef = ref(setMainNavigationMapping());
const categories = computed(() => categoriesRef.value);

const goToLink = (link: string) => {
  emits('closeMenu');
  handleClick(link);
}
</script>

<template>
  <client-only>
    <MobileMenu
      v-if="isSideMenuOpened"
      :categories="categories"
      :start-banner-data="bannerMobile()"
      @close-menu="$emit('closeMenu')"
      @go-to-link="goToLink"
    >
      <template #mobileMenuLinkShowAll="{ category }">
        <LocaleRouterLink
          class="mobile-menu-accordion__link"
          :to="category.to"
          @click="$emit('closeMenu')"
        >
          <span class="mega-menu-accordion__subcategory">{{
            $t('components.navigation.showAllProducts')
          }}</span>
        </LocaleRouterLink>
      </template>
      <template #mobileMenuLinkAccordion="{ category }">
        <a
          v-if="category.externalLink"
          :href="category.to"
          class="mega-menu__link"
          :class="{ 'is--highlight': category?.highlight }"
          :target="category.linkNewTab ? '_blank' : '_self'"
          >{{ category.title }}</a
        >
        <LocaleRouterLink
          v-else
          class="mobile-menu-accordion__link"
          :to="category.to"
          :class="{ 'is--highlight': category.highlight }"
          @click="$emit('closeMenu')"
        >
          <span class="mega-menu-accordion__subcategory">{{
            category.title
          }}</span>
        </LocaleRouterLink>
      </template>
      <template #mobileMenuLink="{ category }">
        <a
          v-if="category.externalLink"
          :href="category.to"
          class="mobile-menu-items__link"
          :target="category.linkNewTab ? '_blank' : '_self'"
        >
          <span class="mobile-menu-items__headline">
            {{ category.title }}
          </span>
          <span v-if="category.subtitle" class="mobile-menu__subheadline">
            {{ category.subtitle }}
          </span></a
        >
        <LocaleRouterLink
          v-else
          class="mobile-menu-items__link"
          :to="category.to"
          @click="$emit('closeMenu')"
        >
          <span class="mobile-menu-items__headline">
            {{ category.title }}
          </span>
          <span v-if="category.subtitle" class="mobile-menu__subheadline">
            {{ category.subtitle }}
          </span>
        </LocaleRouterLink>
      </template>
    </MobileMenu>
  </client-only>
</template>
